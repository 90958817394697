import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteToken } from 'utils/token';

const LogoutPage: React.FC = () => {
  const projectId = useProjectId();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries();
    deleteToken();
    navigate(`/${projectId}/`);
  }, []);

  return <CircularProgress color="secondary" />;
};

export default LogoutPage;
